import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week Starts Today!`}</em></p>
    <p>{`Push Jerk 1-1-1-1 to a 1RM`}</p>
    <p>{`Weighted Strict Pullups 1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`50-Double Unders`}</p>
    <p>{`15-S2OH (115/75)`}</p>
    <p>{`40-Double Unders`}</p>
    <p>{`12-S2OH (135/95)`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`9-S2OH (155/105)`}</p>
    <p>{`20-Double Unders`}</p>
    <p>{`6-S2OH (175/115)`}</p>
    <p>{`10-Double Unders`}</p>
    <p>{`3-S2OH (195/125)`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Ville will close after the 4:30 class next Friday for Oaks.
 `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`East will be closed on Saturday, Derby Day.  We will have one class
at the Ville at 10:00am.  All are invited so bring a friend!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      